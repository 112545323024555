import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';

import InlineEdit from '../../../molecules/InlineEdit';
import UserSelect from '../../../molecules/UserSelect';
import { fetchCecUsers, companySponsorUpdate } from '../../../../services';
import { SelectOption } from '../../../molecules/Select';
import UsersListValue from '../../../atoms/UsersListValue';
import { arraysContainSameElements } from '../../../../helpers/common';

export interface SponsorEditProps {
  isEditable?: boolean;
  title?: React.ReactNode;
  open?: boolean;
  onOpenChange?: Function;
  value?: any;
  onSave?: Function;
  onChange?: Function;
  isLoading?: boolean;
  companyId?: string;
  companyNodeId?: string;
  operationalAdminList: string[];
  pamList: string[];
  editSource: string;
}

const SponsorEdit = ({
  isEditable = true,
  title,
  open,
  onOpenChange,
  value,
  onSave,
  onChange,
  isLoading,
  companyId,
  companyNodeId,
  operationalAdminList,
  pamList,
  editSource
}: SponsorEditProps) => {
  const authData = useSelector((state: any) => state?.auth);
  const { actualUserType } = authData;

  const [edit, setEdit] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [status, setStatus] = useState<string | undefined>();
  const [editValue, setEditValue] = useState<any>();
  const [error, setError] = useState<any | undefined>();

  useEffect(() => {
    setEdit(!!open);
  }, [open]);

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  useEffect(() => {
    validate();
  }, [editValue]);

  const onValueChange = (selectedUsers: any) => {
    if (Array.isArray(selectedUsers) && !selectedUsers?.length) {
      setEditValue(undefined);
      if (onChange) onChange(undefined);
    } else {
      const changedValue = selectedUsers ? [selectedUsers] : undefined;
      setEditValue(changedValue);
      if (onChange) onChange(changedValue);
    }
  };

  const onEditChange = () => {
    setEdit(true);
    if (onOpenChange) {
      onOpenChange(true);
    }
  };

  const onEditCancel = () => {
    setEditValue(value);
    setEdit(false);
    if (onOpenChange) {
      onOpenChange(false);
    }
  };

  const userExistsInAllRole = (val: string) => {
    return operationalAdminList?.includes(val) && pamList?.includes(val);
  };

  const validate = (): Boolean => {
    if (!editValue || editValue?.length === 0) {
      setError({ message: 'Sponsor is required' });
      return false;
    } else {
      let userHas3roles: SelectOption[] = [];
      editValue?.forEach((item: SelectOption) => {
        if (userExistsInAllRole(item?.value)) {
          userHas3roles.push(item);
        }
      });

      if (userHas3roles.length) {
        setError({
          message: 'Not allowed to add more than 2 roles to the same user',
          users: userHas3roles
        });
        return false;
      } else {
        setError(undefined);
      }
    }
    return true;
  };

  const onClickSave = async () => {
    const editedValue = editValue || [];
    const originalValue = value || [];

    setIsSubmitted(true);

    const addList = editValue
      ?.filter(
        (item: any) =>
          !originalValue?.find(
            (innerItem: any) => innerItem.value === item.value
          )
      )
      ?.map((item: any) => {
        return {
          userId: item?.value,
          email: item?.email,
          firstName: item?.firstName,
          lastName: item?.lastName
        };
      });
    const removeList = originalValue
      .filter(
        (item: any) =>
          !editedValue?.find((innerItem: any) => innerItem.value === item.value)
      )
      ?.map((item: any) => {
        return {
          userId: item?.value,
          email: item?.email,
          firstName: item?.firstName,
          lastName: item?.lastName
        };
      });

    if (!validate()) {
      return;
    }

    setStatus('PENDING');

    try {
      const result = await companySponsorUpdate(
        'sponsor',
        actualUserType
          ? actualUserType?.map((item: string) => item?.toUpperCase())
          : [],
        addList,
        removeList,
        {
          companyId: companyId,
          companyNodeId: companyNodeId
        },
        editSource
      );

      setEdit(false);
      if (onOpenChange) {
        onOpenChange(false);
      }
      setStatus('SUCCESS');
      if (onSave) onSave(editValue);
    } catch (err) {
      setStatus('ERROR');
    }
  };

  const loadCecUsers = (inputValue: any, callback: Function) => {
    if (inputValue?.length > 2) {
      fetchCecUsers(inputValue).then((result: any) => {
        callback(
          result?.map((item: any) => {
            return { ...item, label: item.userId, value: item.userId };
          }) || []
        );
      });
    } else {
      callback([]);
    }
  };

  const loadOptions = debounce(loadCecUsers, 600);
  return (
    <div>
      {isSubmitted && edit && error?.message ? (
        <div className="alert alert--warning">
          <div className="alert__icon icon-warning-outline"></div>
          <div className="alert__message">
            {error?.message}

            {error?.users
              ? ' - ' +
                error?.users?.map((item: SelectOption) => item.value).join(' ,')
              : null}
          </div>
        </div>
      ) : null}

      <InlineEdit
        isDisableSave={arraysContainSameElements(
          value?.map(({ email }: any) => email),
          editValue?.map(({ email }: any) => email)
        )}
        isEditable={isEditable}
        title={title}
        readContent={
          <div className="edit-form-value-holder">
            <UsersListValue users={editValue || []} />
          </div>
        }
        isEditing={edit}
        onChangeEdit={onEditChange}
        onCancelEdit={onEditCancel}
        isLoading={status === 'PENDING' || isLoading}
        editContent={
          <div className="edit-form-value-holder">
            <UserSelect
              isClearable
              controlShouldRenderValue={false}
              value={editValue?.length ? editValue[0] : undefined}
              name="sponsor"
              isMulti={false}
              cacheOptions
              placeholder={'Search Sponsor....'}
              loadOptions={loadOptions}
              onChange={onValueChange}
            />
          </div>
        }
        onSave={onClickSave}
      />
    </div>
  );
};

export default SponsorEdit;
