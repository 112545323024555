import { Action } from '../../actions/ActionTypes.interface';

const initialState = {};

export const userActivityReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'USER_ACTIVITY_UPDATE': {
      return {
        ...state,
        ...{ userActivityStatus: action.payload }
      };
    }

    default:
      return state;
  }
};
