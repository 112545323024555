import { ReactNode } from 'react';
import { Tooltip } from 'react-tooltip';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getAddRemovedPayload = (
  existingValue: string[],
  newValue: string[]
): { addedList: string[]; removedList: string[] } => {
  const addedList = newValue.filter((item: string) => {
    return !existingValue.includes(item);
  });
  const removedList = existingValue.filter((item: string) => {
    return !newValue.includes(item);
  });

  return {
    addedList: addedList,
    removedList: removedList
  };
};

export const checkIsChanged = (
  existingData: string[] = [],
  newData: string[] = []
): boolean => {
  if (existingData.length === newData.length) {
    const updatedData = existingData.filter((item: string) => {
      return newData.includes(item);
    });
    if (existingData.length === updatedData.length) {
      return false;
    }
  }
  return true;
};

export const isItemInArrayIgnoreCase = (item: string, array: string[]) => {
  const lowercasedItem = item?.toLowerCase();
  return array.some((element) => element?.toLowerCase() === lowercasedItem);
};

export const isValidInput = (input: string): boolean => {
  // Regular expression to match characters, numbers, dashes, underscores, and spaces
  const pattern = /^[a-zA-Z0-9-_ ]*$/;
  return pattern.test(input) || input === '';
};

export const sanitizeInput = (input: string): string => {
  // Regular expression to match characters, numbers, dashes, underscores, and spaces
  const pattern = /[^a-zA-Z0-9-_ ]+|\s{2,}/g;
  return input.replace(pattern, '');
};

// React js generate every monday & tuesday date from start to end date
export const generateDatesForWeek = (
  startDate: Date,
  endDate: Date,
  days: number[]
) => {
  const dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const dayOfWeek = currentDate.getDay();
    if (days.includes(dayOfWeek)) {
      dates.push(new Date(currentDate));
    }
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

// React js generate every month 31 day date from start to end date
export const generateDatesForMonth = (
  startDate: Date,
  endDate: Date,
  monthDays: number[]
) => {
  const dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const dayOfMonth = currentDate.getDate();
    if (monthDays.includes(dayOfMonth)) {
      dates.push(new Date(currentDate));
    }
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

// React js generate all dates from start to end date
const generateDates = (startDate: Date, endDate: Date) => {
  const dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const dayOfMonth = currentDate.getDate();
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export function arraysContainSameElements<T>(
  prevArrayOrString: T | T[],
  selectedArrayOrString: T | T[]
): boolean {
  const isArray = (value: any): value is T[] => Array.isArray(value);

  const prevArray = isArray(prevArrayOrString)
    ? prevArrayOrString
    : [prevArrayOrString];
  const selectedArray = isArray(selectedArrayOrString)
    ? selectedArrayOrString
    : [selectedArrayOrString];

  const sortedPrevArray = prevArray
    .filter((value) => value != null && value !== '')
    .sort();
  const sortedSelectedArray = selectedArray
    .filter((value) => value != null && value !== '')
    .sort();

  if (sortedPrevArray.length !== sortedSelectedArray.length) {
    return false;
  }

  for (let i = 0; i < sortedPrevArray.length; i++) {
    if (sortedPrevArray[i] !== sortedSelectedArray[i]) {
      return false;
    }
  }

  // If all elements are equal, return true
  return true;
}

export const noteToolTip = (id: string, text: string): ReactNode => {
  return (
    <>
      <span
        className="icon-exclamation-circle text-warning-alt"
        data-tooltip-id={`tooltip-${id}`}
        data-tooltip-place="bottom"
      ></span>
      <Tooltip id={`tooltip-${id}`}>
        <span style={{ zIndex: 1111 }}>{text}</span>
      </Tooltip>
    </>
  );
};

export const exportToExcel = async (getData: Function, fileName: string) => {
  const data = await getData();
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array'
  });
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  const timestamp = moment().format('YYYYMMMDD_HHmmss');
  saveAs(blob, `${fileName}_${timestamp}.xlsx`);
};
