import { useOktaAuth, withOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import InactivityHandler from '../../organisms/InactivityHandler';

const PrivateWrapper = () => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();
  const [isTokenRenewing, setIsTokenRenewing] = useState(false);
  const userActivity = useSelector((state) => state.userActivity);

  const loginRedirect = async () => {
    const fromUri = window.location.href;
    let urlQuery = '';
    if (fromUri) {
      urlQuery = '?redirectUrl=' + encodeURIComponent(fromUri);
    }
    //window.location.href = '/login' + urlQuery;
    oktaAuth.signInWithRedirect({ originalUri: fromUri });
  };

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      loginRedirect();
      return;
    } else {
      const accessToken = authState?.accessToken?.accessToken || '';
      const userData = {
        ...(authState?.idToken?.claims || {}),
        ...{ accessToken }
      };
      dispatch({ type: 'AUTH_COMPLETE', payload: userData });
    }
  }, [oktaAuth, authState, authState?.isAuthenticated]);

  async function renewToken() {
    try {
      const tokens = await oktaAuth.tokenManager.getTokens();
      const accessToken = tokens.accessToken;
      const refreshToken = tokens.refreshToken;
      if (accessToken && refreshToken) {
        const now = Math.floor(Date.now() / 1000);
        const expiration = accessToken.expiresAt - 60; // Renew 1 minute before expiration

        // console.log(
        //   'Now :',
        //   moment.unix(now).format('MM/DD/YYYY HH:mm:ss'),
        //   'expiration : ',
        //   moment.unix(expiration).format('MM/DD/YYYY HH:mm:ss')
        // );

        if (now > expiration && !isTokenRenewing) {
          setIsTokenRenewing(true);
          const refreshedTokens = await oktaAuth.token.renewTokens(
            tokens.accessToken
          );
          // Save the new tokens
          oktaAuth.tokenManager.setTokens(refreshedTokens);
          setIsTokenRenewing(false);
          console.log('Token successfully renewed:', refreshedTokens);
        }
      } else {
        console.error('No refresh token available.');
      }
    } catch (error) {
      console.error('Token renewal failed:', error);
      // Handle the error (e.g., redirect to login)
      if (error.name === 'AuthSdkError' && error.errorCode === 'INTERNAL') {
        // Specific handling for internal errors
        console.error('An internal error occurred, please try again later.');
      } else if (
        error.name === 'OAuthError' &&
        error.errorCode === 'login_required'
      ) {
        // Specific handling for login required errors
        console.error('User needs to log in again.');
        // Optionally redirect to login page
        oktaAuth.signInWithRedirect();
      }
    }
  }

  async function renewTokenProcess() {
    if (
      userActivity?.userActivityStatus ||
      typeof userActivity?.userActivityStatus === 'undefined'
    ) {
      await renewToken();
    }
  }

  const onReLoginClick = () => {
    dispatch({
      type: 'USER_ACTIVITY_UPDATE',
      payload: true
    });
    localStorage.setItem('lastActivity', Date.now().toString());
  };

  useEffect(() => {
    renewTokenProcess();
    const interval = setInterval(() => {
      renewTokenProcess();
    }, 10000); // 10 seconds

    return () => clearInterval(interval);
  }, []);

  if (!authState || !authState?.isAuthenticated) {
    return <></>;
  }

  return (
    <>
      <Outlet />

      <InactivityHandler onReLoginClick={onReLoginClick} />
    </>
  );
};

export default withOktaAuth(PrivateWrapper);
