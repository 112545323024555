import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Dialog from '../../atoms/Dialog';
import { config } from '../../../config/config';

export interface InactivityHandlerProps {
  onInactivity?: Function;
  onReLoginClick?: Function;
  timeout?: number;
}

const InactivityHandler = ({
  timeout = 6000,
  onInactivity,
  onReLoginClick
}: InactivityHandlerProps) => {
  const dispatch = useDispatch();
  const stateRef = useRef<{ userActivityStatus: boolean | undefined }>();
  const [open, setOpen] = useState<Boolean>(false);

  const userActivity = useSelector((state: any) => state.userActivity);
  const checkLastActivity = () => {
    const lastActivity = localStorage.getItem('lastActivity');
    const now = Date.now();
    const differenceInMilliseconds = Math.abs(
      (lastActivity ? Number(lastActivity) : now) - now
    );
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

    if (
      differenceInMinutes > config?.userInActivityTimeOut &&
      stateRef.current?.userActivityStatus !== false
    ) {
      dispatch({
        type: 'USER_ACTIVITY_UPDATE',
        payload: false
      });
      setOpen(true);
    }
  };

  useEffect(() => {
    stateRef.current = { userActivityStatus: userActivity?.userActivityStatus };
  }, [userActivity?.userActivityStatus]);

  const resetTimer = (update = true) => {
    if (update) {
      localStorage.setItem('lastActivity', Date.now().toString());
    }
  };

  useEffect(() => {
    const handleActivity = () => {
      resetTimer(
        stateRef.current?.userActivityStatus ||
          typeof stateRef.current?.userActivityStatus === 'undefined'
      );
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('mousedown', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('touchmove', handleActivity);

    // Initial timer setup
    resetTimer(
      userActivity?.userActivityStatus ||
        typeof userActivity?.userActivityStatus === 'undefined'
    );
    checkLastActivity();

    const interval = setInterval(() => {
      checkLastActivity();
    }, 2000);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('mousedown', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('touchmove', handleActivity);
      localStorage.removeItem('lastActivity');
      clearInterval(interval);
    };
  }, [timeout, onInactivity]);

  const dialogClose = () => {
    setOpen(false);
    // if (onChange) onChange(false);
  };

  const reLogin = () => {
    setOpen(false);
    if (onReLoginClick) onReLoginClick();
  };

  return (
    <>
      <Dialog
        open={open}
        size="medium"
        showCloseButton={false}
        onDialogClose={dialogClose}
        closeOnClickOutside={false}
        title={``}
        footer={false}
        body={
          <div className="text-center">
            <h2>Still with us?</h2>
            <div>
              <span className="icon-activities text-size-48 text-default"></span>
              <span className="icon-clock text-size-48  base-margin-left text-default"></span>
            </div>
            <p>
              Thank you for using <b>SC User Registration</b>.
            </p>

            <p>
              Your session is expired due to inactivity. Click{' '}
              <a onClick={reLogin}>here</a> to login again.
            </p>
          </div>
        }
      />
    </>
  );
};

export default InactivityHandler;
