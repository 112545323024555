import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Tooltip } from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import httpUtil from '../../../helpers/interceptor';
import Loader from '../../atoms/Loader/Loader';
import notify from '../../../helpers/notification';
import { paInviteUpdate } from '../../../services/CompanyService';
import PAInviteProcessDialog from '../../organisms/PAInviteProcessDialog';

const InvitePAUserEdit = ({
  isEditable,
  companyId,
  companyNodeId,
  emailDomains,
  paUserList,
  title = 'Designated Partner Administrators',
  maxAddLimitSkip = false,
  onSave,
  setIsVisibleInvitePa,
  editSource
}) => {
  const [paList, setPaList] = useState([]);

  const [addingPaList, setAddingPaList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState([]);
  const authData = useSelector((state) => state?.auth);
  const { actualUserType, user, userActiveRolePermissions } = authData;

  const [selectedUser, setSelectedUser] = useState();
  const [statusAction, setStatusAction] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    if (!paList || paUserList?.length === 0) {
      setAddingPaList([
        {
          firstName: '',
          lastName: '',
          email: ''
        }
      ]);
    }
  }, [paList]);

  useEffect(() => {
    if (paUserList && paUserList?.length) {
      setPaList(paUserList);
    }
  }, [paUserList]);

  const addMorePa = () => {
    const paListTemp = cloneDeep(addingPaList);
    if (paListTemp.length <= 1 || maxAddLimitSkip) {
      paListTemp.push({ firstName: '', lastName: '', email: '' });
      setAddingPaList(paListTemp);
    }
  };

  const removePa = (index) => {
    const paListTemp = cloneDeep(addingPaList);
    paListTemp.splice(index, 1);
    setAddingPaList(paListTemp);
  };

  const handleUserInput = (event, index) => {
    const paListTemp = cloneDeep(addingPaList);
    let paRow = paListTemp[index];
    paRow = { ...paRow, ...{ [event.target.name]: event.target.value } };
    paListTemp[index] = paRow;
    setAddingPaList(paListTemp);
  };

  const sendInvite = async (index) => {
    const paUser =
      addingPaList && addingPaList?.length ? addingPaList[index] : null;
    if (paUser) {
      try {
        /* PA validation start */
        const paListTemp = cloneDeep(addingPaList);
        const item = paListTemp[index];
        let errorList = [];
        let paEmail = item?.email?.trim();
        let paItemError = [];
        if (!item?.firstName?.trim()) {
          paItemError['firstName'] = 'First name is required';
        }
        if (!item?.lastName?.trim()) {
          paItemError['lastName'] = 'Last name is required';
        }
        if (!paEmail) {
          paItemError['email'] = 'Email is required';
        } else {
          let emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
          if (!emailRegexp.test(paEmail)) {
            paItemError['email'] = 'Invalid Email';
          } else {
            const selectedEmailDomains = emailDomains || [];
            let isEmailValid = false;
            selectedEmailDomains?.forEach((mailDomainItem) => {
              if (paEmail.endsWith(mailDomainItem)) {
                isEmailValid = true;
              }
            });
            if (!isEmailValid)
              paItemError['email'] =
                'PA email domain must be same as added email domain';

            //check duplicate mail
            const allEmails = [...(paList?.map((res) => res?.email) || [])];
            var isDuplicateEmail = addingPaList.some(function (item, idx) {
              return allEmails.includes(item?.email);
            });
            if (isDuplicateEmail) {
              paItemError['email'] = 'PA email already in use';
            }
          }
        }

        // check email from server
        if (!paItemError['email']) {
          setIsLoading(true);
          const result = await httpUtil.post(
            `/api/am/dashboard/v1/findUsersUsingEmail`,
            {
              searchKey: item.email
            }
          );

          setIsLoading(false);

          if (result) {
            paItemError['email'] =
              'PA email already in use. Please search through PA List';
          } else {
            setAddingPaList([]);
          }
        }

        if (Object.keys(paItemError)?.length) {
          errorList[index] = paItemError;
        }

        if (!errorList?.length) {
          const payload = {
            companyId: companyId,
            companyNodeId: companyNodeId,
            userType: actualUserType
              ? actualUserType?.map((item) => item?.toUpperCase())
              : [],
            paList: [
              {
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email
              }
            ],
            editSource: editSource
          };
          await httpUtil.post(`/api/am/dashboard/v1/paRequestData`, payload);
          const paListTemp = cloneDeep(addingPaList);
          paListTemp[index]['isSubmitted'] = true;
          paListTemp[index]['status'] = 'IN_PROCESS';

          const updatedPAList = [
            ...(paList || []),
            ...[
              {
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email,
                status: 'IN_PROCESS'
              }
            ]
          ];
          setPaList(updatedPAList);

          onSave(updatedPAList);

          notify('Partner Administrator is invited successfully.', 'success');
        }

        /* PA validation end */
        setValidation(errorList);
      } catch (e) {
        const errorMessage = `An error occurred while inviting Pa ${
          e?.response?.data?.error ? ': ' + e?.response?.data?.error : ''
        }`;
        notify(errorMessage, 'error');
      }
    }
  };

  const isExpired = (date) => {
    const a = moment(date);
    const b = moment();
    const noOfDays = a.diff(b, 'days');
    return noOfDays < -14;
  };

  const onClickActionButton = (userRow, action) => {
    setSelectedUser(userRow);
    setStatusAction(action);
    setConfirmDialogOpen(true);
  };

  const updatePAInviteStatus = async (userRow, action) => {
    if (action === 'RESEND' || action === 'CANCEL' || action === 'REMOVE') {
      try {
        setIsLoading(true);
        const payload = {
          userType: actualUserType
            ? actualUserType?.map((item) => item?.toUpperCase())
            : [],
          companyId: companyId,
          companyNodeId: companyNodeId,
          paList: [
            {
              firstName: userRow?.firstName,
              lastName: userRow?.firstName,
              email: userRow?.email
            }
          ]
        };

        let updatedPAList = paList;
        if (action === 'RESEND') {
          payload.action = 'ResendInvite';
          updatedPAList = paList?.map((item) => {
            let itemTemp = item;
            if (itemTemp?.email === userRow?.email) {
              itemTemp.inviteDate = new Date().toISOString();
            }
            return itemTemp;
          });
        } else if (action === 'CANCEL') {
          payload.action = 'CancelInvite';
          updatedPAList = paList?.filter((item) => {
            return item?.email !== userRow?.email;
          });
        } else if (action === 'REMOVE') {
          payload.action = 'RemoveDeniedInvite';
          updatedPAList = paList?.filter((item) => {
            return item?.email !== userRow?.email;
          });
        }

        await paInviteUpdate(payload);

        setIsLoading(false);
        setSelectedUser(undefined);
        setStatusAction(undefined);
        setConfirmDialogOpen(false);

        setPaList(updatedPAList);
        onSave(updatedPAList);

        let successMessage = '';
        if (action === 'RESEND') {
          successMessage = 're-sent';
        } else if (action === 'CANCEL') {
          successMessage = 'cancelled';
        } else if (action === 'REMOVE') {
          successMessage = 'removed';
        }
        notify(`Invite has been  ${successMessage} successfully.`, 'success');
      } catch (error) {
        setIsLoading(false);
        let failedMessage = '';
        if (action === 'RESEND') {
          failedMessage = 'resending';
        } else if (action === 'CANCEL') {
          failedMessage = 'cancelling';
        } else if (action === 'REMOVE') {
          failedMessage = 'removing';
        }

        const errorMessage = `An error occurred while ${failedMessage} invite of partner administrator ${
          error?.response?.data?.error
            ? ': ' + error?.response?.data?.error
            : ''
        }`;
        notify(errorMessage, 'error');
      }
    }
  };

  return (
    <div className="">
      <div className="card">
        <div className="card-header flex flex-between">
          <h5 className="text-size-16 text-weight-700">{title}</h5>
          {paList?.every((e) => !e.isSubmitted) && (
            <span
              className="btn label label--dark label--tiny inline-edit-actions-item"
              onClick={setIsVisibleInvitePa}
            >
              Cancel
            </span>
          )}
        </div>

        <div className="card-body">
          <div className="responsive-table">
            <table className="table table--lined">
              <tbody>
                {paList &&
                  paList?.map((item, index) => {
                    const isInviteExpired = isExpired(item?.inviteDate);
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <>
                            <td style={{ width: '100%' }}>
                              <div className="flex">
                                <div
                                  className="qtr-margin-right"
                                  style={{ width: 'calc(100% - 120px)' }}
                                >
                                  {item?.firstName} {item?.lastName} (
                                  {item?.email})
                                </div>
                                <div
                                  className="flex"
                                  style={{ width: '120px' }}
                                >
                                  <div>
                                    {item?.status?.toLowerCase() ===
                                    'nomination_accepted' ? (
                                      <span
                                        className="icon-check-outline text-success qtr-margin-left"
                                        style={{ cursor: 'pointer' }}
                                        data-tooltip-id={`tooltip-user-status${index}`}
                                      ></span>
                                    ) : null}
                                    {item?.status?.toLowerCase() ===
                                    'in_process' ? (
                                      <>
                                        {isInviteExpired ? (
                                          <span
                                            className="icon-clock text-warning qtr-margin-left"
                                            style={{ cursor: 'pointer' }}
                                            data-tooltip-id={`tooltip-user-status${index}`}
                                          ></span>
                                        ) : (
                                          <span
                                            className="icon-time text-warning-alt qtr-margin-left"
                                            style={{ cursor: 'pointer' }}
                                            data-tooltip-id={`tooltip-user-status${index}`}
                                          ></span>
                                        )}
                                      </>
                                    ) : null}
                                    {item?.status?.toLowerCase() ===
                                    'denied' ? (
                                      <span
                                        className="icon-blocked text-danger qtr-margin-left"
                                        style={{ cursor: 'pointer' }}
                                        data-tooltip-id={`tooltip-user-status${index}`}
                                      ></span>
                                    ) : null}

                                    {item?.status?.toLowerCase() !==
                                      'in_process' &&
                                    item?.status?.toLowerCase() !==
                                      'nomination_accepted' &&
                                    item?.status?.toLowerCase() !== 'denied' ? (
                                      <span
                                        className="icon-info text-muted qtr-margin-left"
                                        style={{ cursor: 'pointer' }}
                                        data-tooltip-id={`tooltip-user-status${index}`}
                                      ></span>
                                    ) : null}
                                    <Tooltip id={`tooltip-user-status${index}`}>
                                      <span style={{ zIndex: 1111 }}>
                                        {item?.status?.toLowerCase() ===
                                          'in_process' && isInviteExpired
                                          ? 'INVITE EXPIRED'
                                          : item?.status?.replace(/_/gi, ' ')}
                                      </span>
                                    </Tooltip>
                                  </div>
                                  <div className="half-margin-left">
                                    {item?.status?.toLowerCase() ===
                                    'in_process' ? (
                                      <>
                                        {isInviteExpired ? (
                                          <button
                                            type="button"
                                            className="btn btn--small"
                                            onClick={() =>
                                              onClickActionButton(
                                                item,
                                                'RESEND'
                                              )
                                            }
                                          >
                                            Resend
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn btn--small btn--dark"
                                            onClick={() =>
                                              onClickActionButton(
                                                item,
                                                'CANCEL'
                                              )
                                            }
                                          >
                                            Cancel
                                          </button>
                                        )}
                                      </>
                                    ) : null}

                                    {item?.status?.toLowerCase() ===
                                    'denied' ? (
                                      <button
                                        type="button"
                                        className="btn btn--small btn--ghost"
                                        onClick={() =>
                                          onClickActionButton(item, 'REMOVE')
                                        }
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </>
                        </tr>
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </table>
          </div>

          {isEditable ? (
            <div className="responsive-table">
              <table
                className="table table--lined table--fixed"
                style={{ width: '100%' }}
              >
                <tbody>
                  {addingPaList &&
                    addingPaList?.map(
                      (
                        { firstName, lastName, email, isSubmitted, status },
                        index
                      ) => (
                        <React.Fragment key={index}>
                          <tr>
                            <>
                              <td style={{ width: '25%' }}>
                                <div className={`form-group qtr-margin-bottom`}>
                                  <div className="form-group__text">
                                    <input
                                      id={`input-paFirstName${index}`}
                                      disabled={isSubmitted}
                                      type="text"
                                      value={firstName}
                                      name="firstName"
                                      onChange={(e) =>
                                        handleUserInput(e, index)
                                      }
                                    />
                                    <label
                                      htmlFor={`input-paFirstName${index}`}
                                      className="text-weight-700 required"
                                    >
                                      First Name
                                    </label>
                                  </div>
                                  {validation &&
                                  validation.length &&
                                  validation[index]?.firstName ? (
                                    <span className="text-danger text-size-12">
                                      {validation[index]?.firstName}
                                    </span>
                                  ) : null}
                                </div>
                              </td>
                              <td style={{ width: '25%' }}>
                                <div className={`form-group qtr-margin-bottom`}>
                                  <div className="form-group__text">
                                    <input
                                      id={`input-paLastName${index}`}
                                      disabled={isSubmitted}
                                      type="text"
                                      value={lastName}
                                      name="lastName"
                                      onChange={(e) =>
                                        handleUserInput(e, index)
                                      }
                                    />
                                    <label
                                      htmlFor={`input-paLastName${index}`}
                                      className="text-weight-700 required"
                                    >
                                      Last Name
                                    </label>
                                  </div>
                                  {validation &&
                                  validation.length &&
                                  validation[index]?.lastName ? (
                                    <span className="text-danger text-size-12">
                                      {validation[index]?.lastName}
                                    </span>
                                  ) : null}
                                </div>
                              </td>
                              <td style={{ width: '25%' }}>
                                <div className={`form-group qtr-margin-bottom`}>
                                  <div className="form-group__text">
                                    <input
                                      id={`input-paEmail${index}`}
                                      disabled={isSubmitted}
                                      type="email"
                                      value={email}
                                      name="email"
                                      onChange={(e) =>
                                        handleUserInput(e, index)
                                      }
                                    />
                                    <label
                                      htmlFor={`input-paEmail${index}`}
                                      className="text-weight-700 required"
                                    >
                                      Email
                                    </label>
                                  </div>
                                  {validation &&
                                  validation.length &&
                                  validation[index]?.email ? (
                                    <span
                                      className="text-danger text-size-12"
                                      style={{ textWrap: 'wrap' }}
                                    >
                                      {validation[index]?.email}
                                    </span>
                                  ) : null}
                                </div>
                              </td>
                              <td style={{ width: '25%' }}>
                                {!isSubmitted ? (
                                  <button
                                    type="button"
                                    onClick={() => removePa(index)}
                                    className="btn btn--ghost"
                                  >
                                    Remove
                                  </button>
                                ) : (
                                  <div></div>
                                )}
                              </td>
                            </>
                          </tr>
                          {(!isSubmitted ||
                            (index === addingPaList.length &&
                              onSave &&
                              addingPaList.every(
                                ({ isSubmitted }) => isSubmitted
                              ))) && (
                            <tr>
                              {!isSubmitted && (
                                <td colSpan={4} className="text-right">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      sendInvite(index);
                                    }}
                                    className="btn btn--ghost"
                                    style={{ margin: '5px' }}
                                  >
                                    Send invite
                                  </button>
                                </td>
                              )}
                            </tr>
                          )}
                        </React.Fragment>
                      )
                    )}
                  {!addingPaList.length ? (
                    <button
                      type="button"
                      onClick={addMorePa}
                      className="btn btn--ghost pull-right"
                      style={{ margin: '5px' }}
                    >
                      Add more PA
                    </button>
                  ) : null}
                </tbody>
              </table>
            </div>
          ) : null}
          {isLoading ? (
            <div className="center-holder-wrap">
              <Loader />
            </div>
          ) : null}
        </div>
      </div>

      <PAInviteProcessDialog
        isLoading={isLoading}
        details={selectedUser}
        open={confirmDialogOpen}
        action={statusAction || ''}
        onChange={(dialogStatus) => {
          setConfirmDialogOpen(dialogStatus);
        }}
        onSuccess={updatePAInviteStatus}
      />
    </div>
  );
};
export default InvitePAUserEdit;
