const initialState = {};

export const authReducer = (state = initialState, action) => {
  const userActiveRolePermissions = (rolePermissions = [], userRoles = []) => {
    return (rolePermissions || [])
      ?.filter((item) => {
        return userRoles?.find(
          (perItem) => item?.name?.toLowerCase() === perItem?.toLowerCase()
        );
      })
      ?.sort((a, b) => (a?.priority > b?.priority ? 1 : -1));
  };

  switch (action.type) {
    case 'AUTH_COMPLETE': {
      const userDetails = action.payload;

      /* FIND USER TYPE */
      let mainUserType = 'EXTERNAL'; // access_level=1, access_level=2 will be External Partners
      /* access_level=4 for Internal users */
      if (userDetails.access_level == 4) {
        mainUserType = 'INTERNAL';
      }

      const mainUseTypeToRole =
        mainUserType === 'INTERNAL' ? 'InternalUser' : 'ExternalUser';

      const userGroups = userDetails?.groups || [];
      const userRoles = [
        ...(state?.userRoles || []),
        mainUseTypeToRole,
        ...userGroups
      ];

      return {
        ...state,
        ...{
          status: 'SUCCESS',
          user: action.payload,
          mainUserType: mainUserType,
          userRoles,
          userActiveRolePermissions: userActiveRolePermissions(
            state?.rolePermissions || [],
            userRoles
          )
        }
      };
    }
    case 'AUTH_START': {
      return { ...state, ...{ status: 'PENDING' } };
    }
    case 'AUTH_FAILURE': {
      return {
        ...state,
        ...{ status: 'FAILURE', errorMessage: action.payload }
      };
    }

    case 'CHECK_DOMAIN_REGISTERED_COMPLETE': {
      return {
        ...state,
        ...{
          domainCompanyRegisteredStatus: 'SUCCESS',
          isDomainCompanyRegistered: action.payload
        }
      };
    }
    case 'CHECK_DOMAIN_REGISTERED_START': {
      return {
        ...state,
        ...{
          domainCompanyRegisteredStatus: 'PENDING',
          isDomainCompanyRegistered: undefined
        }
      };
    }
    case 'CHECK_DOMAIN_REGISTERED_FAILURE': {
      return {
        ...state,
        ...{
          domainCompanyRegisteredStatus: 'FAILURE',
          domainCompanyRegisteredErrorMessage: action.payload,
          isDomainCompanyRegistered: undefined
        }
      };
    }

    case 'GET_IS_FEDERATED_USER_COMPLETE': {
      return {
        ...state,
        ...{
          getIsFederatedUserStatus: 'SUCCESS',
          isFederatedUser: action.payload
        }
      };
    }
    case 'GET_IS_FEDERATED_USER_START': {
      return {
        ...state,
        ...{ getIsFederatedUserStatus: 'PENDING', isFederatedUser: undefined }
      };
    }
    case 'GET_IS_FEDERATED_USER_FAILURE': {
      return {
        ...state,
        ...{
          getIsFederatedUserStatus: 'FAILURE',
          getIsFederatedUserErrorMessage: action.payload,
          isFederatedUser: undefined
        }
      };
    }

    case 'GET_USERTYPE_COMPLETE': {
      const actualUserType = action.payload?.userType
        ? action.payload?.userType
            ?.split(',')
            .map((item) => item?.toLowerCase())
            .map((item) => (item === 'external' ? 'requester' : item))
        : [];
      const userType = actualUserType.map((item) =>
        item === 'pa_inprocess' ? 'requester' : item
      );

      const userRoles = [...(state?.userRoles || []), ...userType];

      return {
        ...state,
        ...{
          getUserTypeStatus: 'SUCCESS',
          userType: userType || [],
          actualUserType: actualUserType || [],
          userRoles,
          userActiveRolePermissions: userActiveRolePermissions(
            state?.rolePermissions || [],
            userRoles
          )
        }
      };
    }
    case 'GET_USERTYPE_START': {
      return {
        ...state,
        ...{
          getUserTypeStatus: 'PENDING',
          userType: undefined
        }
      };
    }
    case 'GET_USERTYPE_FAILURE': {
      return {
        ...state,
        ...{
          getUserTypeStatus: 'FAILURE',
          getUserTypeErrorMessage: action.payload,
          userType: undefined
        }
      };
    }

    case 'GET_ROLE_PERMISSION_COMPLETE': {
      const rolePermissions =
        action.payload?.sort((a, b) => (a?.priority > b?.priority ? 1 : -1)) ||
        [];

      return {
        ...state,
        ...{
          getRolePermissionsStatus: 'SUCCESS',
          rolePermissions: rolePermissions,
          actualRolePermissions: rolePermissions,
          userActiveRolePermissions: userActiveRolePermissions(
            rolePermissions || [],
            state?.userRoles
          ),
          getRolePermissionsErrorMessage: null
        }
      };
    }
    case 'GET_ROLE_PERMISSION_START': {
      return {
        ...state,
        ...{
          getRolePermissionsStatus: 'PENDING',
          rolePermissions: undefined,
          getRolePermissionsErrorMessage: undefined
        }
      };
    }
    case 'GET_ROLE_PERMISSION_FAILURE': {
      return {
        ...state,
        ...{
          getRolePermissionsStatus: 'FAILURE',
          rolePermissions: undefined,
          getRolePermissionsErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_SCOUSER_GROUP_COMPLETE': {
      return {
        ...state,
        ...{
          getSCOUserGroupStatus: 'SUCCESS',
          scoUserGroupStatus: action.payload,
          getSCOUserGroupErrorMessage: null
        }
      };
    }
    case 'GET_SCOUSER_GROUP_START': {
      return {
        ...state,
        ...{
          getSCOUserGroupStatus: 'PENDING',
          scoUserGroupStatus: undefined,
          getSCOUserGroupErrorMessage: undefined
        }
      };
    }
    case 'GET_SCOUSER_GROUP_FAILURE': {
      return {
        ...state,
        ...{
          getSCOUserGroupStatus: 'FAILURE',
          scoUserGroupStatus: undefined,
          getSCOUserGroupErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_USER_NOTIFY_INFO_COMPLETE': {
      return {
        ...state,
        ...{
          getUserNotifyInfoStatus: 'SUCCESS',
          userNotifyInfo: action.payload,
          getUserNotifyInfoErrorMessage: null
        }
      };
    }
    case 'GET_USER_NOTIFY_INFO_START': {
      return {
        ...state,
        ...{
          getUserNotifyInfoStatus: 'PENDING',
          userNotifyInfo: undefined,
          getUserNotifyInfoErrorMessage: undefined
        }
      };
    }
    case 'GET_USER_NOTIFY_INFO_FAILURE': {
      return {
        ...state,
        ...{
          getUserNotifyInfoStatus: 'FAILURE',
          userNotifyInfo: undefined,
          getUserNotifyInfoErrorMessage: action.errorMessage
        }
      };
    }

    default:
      return state;
  }
};
