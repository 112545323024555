import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import OrgEditDialog from '../../molecules/OrgEditDialog';

const Profile = ({ profile = {} }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const authData = useSelector((state) => state.auth);
  const { user, mainUserType } = authData;

  const onClickEditDialogOpen = () => {
    setEditDialogOpen(true);
  };

  const {
    city,
    firstName,
    lastName,
    mail,
    registrationStatus,
    mcocompany,
    mcompanytype,
    mcovendorid,
    postalCode,
    state,
    street,
    telephoneNumber,
    userid,
    pa,
    mcouserorg,
    mcomultiorg,
    profileStatus,
    siteId
  } = profile;

  return (
    <div className="">
      {profile && (
        <>
          {/* <div className="base-margin-bottom">
            <h5 className="text-weight-600 ">
              You are an authorized Supply Chain user.{' '}
              {profile.mcocompany &&
                'Please find your profile information below.'}
            </h5>
          </div> */}

          <div className="card qtr-margin-top">
            <div className="card-header">
              <h5>User Details</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                  <div className="record-info">
                    <span className="record-label">User Id</span>
                    <div>
                      <span className="record-value">{user?.ccoid || '-'}</span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Email</span>
                    <div>
                      <span className="record-value">{user?.email || '-'}</span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                  <div className="record-info">
                    <span className="record-label">First Name</span>
                    <div>
                      <span className="record-value">
                        {user?.first_name || '-'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Last Name</span>
                    <div>
                      <span className="record-value">
                        {user?.last_name || '-'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Profile Status</span>
                    <div>
                      <span className="record-value">
                        {profileStatus || '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card qtr-margin-top">
            <div className="card-header">
              <h5>
                Company Details
                {mainUserType === 'EXTERNAL' &&
                profileStatus?.toLowerCase() === 'active' ? (
                  <button
                    className="btn btn--ghost btn--small pull-right"
                    onClick={() => {
                      onClickEditDialogOpen();
                    }}
                    style={{ marginTop: '-3px' }}
                  >
                    Modify Organization
                  </button>
                ) : null}
              </h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Company Name</span>
                    <div>
                      <span className="record-value">{mcocompany || '-'}</span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Vendor ID</span>
                    <div>
                      <span className="record-value">{mcovendorid || '-'}</span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Site Id</span>
                    <div>
                      <span className="record-value">{siteId || '-'}</span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">DUNS Number</span>
                    <div>
                      <span className="record-value">
                        {profile.dunsNumber || '-'}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Address Line 1</span>
                    <div>
                      <span className="record-value">
                        {profile?.address1?.trim() != '0' &&
                        profile?.address1?.trim()
                          ? profile?.address1
                          : '-'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Address Line 2</span>
                    <div>
                      <span className="record-value">
                        {profile?.address2?.trim() != '0' &&
                        profile?.address2?.trim()
                          ? profile?.address2
                          : '-'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">City</span>
                    <div>
                      <span className="record-value">
                        {city?.trim() != '0' && city?.trim() ? city : '-'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">State/Province/Region</span>
                    <div>
                      <span className="record-value">
                        {state?.trim() != '0' && state?.trim() ? state : '-'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Country</span>
                    <div>
                      <span className="record-value">
                        {profile?.country?.trim() != '0' &&
                        profile?.country?.trim()
                          ? profile?.country
                          : '-'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Zip/Postal Code</span>
                    <div>
                      <span className="record-value">
                        {postalCode?.trim() != '0' && postalCode?.trim()
                          ? postalCode
                          : '-'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Phone</span>
                    <div>
                      <span className="record-value">
                        {telephoneNumber?.trim() != '0' &&
                        telephoneNumber?.trim()
                          ? telephoneNumber
                          : '-'}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Company Type</span>
                    <div>
                      <span className="record-value">
                        {mcompanytype || '-'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Partner Administrators</span>
                    <div>
                      <span className="record-value">
                        {pa && pa?.length ? pa?.join(', ') : '-'}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Primary Organization</span>
                    <div>
                      <span className="record-value">{mcouserorg || '-'}</span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Secondary Organization</span>
                    <div>
                      <span className="record-value">
                        {mcomultiorg?.split(',')?.join(', ') || '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <OrgEditDialog
        profile={profile || {}}
        open={editDialogOpen}
        onChange={(dialogStatus) => {
          setEditDialogOpen(dialogStatus);
        }}
      />
    </div>
  );
};
export default Profile;
