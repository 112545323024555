import React from 'react';
import { exportToExcel } from '../../../helpers/common';
import './index.css';

type excelExportType = {
  title?: string;
  fileName: string;
  hidden: boolean;
  disabled?: boolean;
  loading?: boolean;
  getData: Function;
  type?: string;
};

const ExcelExport = ({
  fileName,
  hidden,
  getData,
  disabled = false,
  loading = false,
  title = 'Excel Export',
  type = 'button'
}: excelExportType) => {
  return (
    <>
      {!hidden && (
        <>
          <button
            disabled={disabled}
            type="button"
            onClick={() => exportToExcel(getData, fileName)}
            className={`btn ${type == 'button' ? 'btn--ghost' : 'btn--link'}`}
          >
            {' '}
            <span className="icon-file-excel-o"></span> {title}{' '}
            {loading && (
              <span
                className={`icon-refresh spin`}
                style={{ lineHeight: '24px' }}
              ></span>
            )}
          </button>
        </>
      )}
    </>
  );
};

export default ExcelExport;
