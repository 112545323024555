import React from 'react';
import { useParams } from 'react-router-dom';

import CompanyEdit from '../../organisms/companyEdit';

const CompanyRevalidationDetails = () => {
  let { id: companyId = '' } = useParams();
  return (
    <div className="container">
      <CompanyEdit companyId={companyId} />
    </div>
  );
};
export default CompanyRevalidationDetails;
