import React from 'react';

import AsyncSelect from '../Select/AsyncSelect';
import { components, OptionProps } from 'react-select';
import { SelectOption } from '../Select/Select';

export const filterSelectOption = (
  renderOptions: Function,
  props: OptionProps<SelectOption>
) => {
  const { data } = props;
  return (
    <div>
      <components.Option className="half-padding" {...props}>
        {renderOptions(data)}
      </components.Option>
    </div>
  );
};

const FilterSelect = ({
  value,
  onChange,
  showSaveBtn,
  filterSelectRendered = undefined,
  renderOptions = '',
  ...props
}: any) => {
  const removeValue = (rowValue: string) => {
    const existingValue = value || [];
    const filteredValue = existingValue.filter(
      (item: SelectOption) => item.value !== rowValue
    );

    if (onChange) onChange(filteredValue);
  };

  if (value) value = Array.isArray(value) ? value : [value];

  return (
    <>
      <div className="flex flex-between flex-middle">
        <div style={{ flex: 1 }}>
          <AsyncSelect
            value={value}
            onChange={onChange}
            components={{
              Option: (props: any) => filterSelectOption(renderOptions, props)
            }}
            {...props}
          />
        </div>
      </div>
    </>
  );
};

export default FilterSelect;
