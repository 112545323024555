import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const UserProfileMandatoryUpdate = () => {
  const [show, setShow] = useState(false);

  const userDetailsData: any = useSelector<any>((state) => state.auth);
  const { mainUserType } = userDetailsData;
  const companyRegisterData = useSelector(
    (state: any) => state?.companyRegisterData
  );
  const { profile, getProfileStatus } = companyRegisterData;

  useEffect(() => {
    if (mainUserType === 'EXTERNAL') {
      if (getProfileStatus === 'SUCCESS') {
        if (profile?.orgsUpdateRequire === true) {
          setShow(true);
        } else {
          setShow(false);
        }
      }
    } else {
      setShow(false);
    }
  }, [mainUserType, profile, getProfileStatus]);

  return (
    <>
      {show ? (
        <div className="container">
          <div className="alert alert--danger">
            <div className="alert__message" style={{ width: '100%' }}>
              <div className="marquee">
                <span>
                  Company organization has been changed. Please update your
                  primary organization.
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default UserProfileMandatoryUpdate;
