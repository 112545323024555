import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { hasAccess } from '../../../helpers';
import DropdownMenu from '../../molecules/DropdownMenu';
import LimitDropdown from '../../atoms/LimitDropdown';
import Dropdown from '../../atoms/Dropdown';
import './index.css';

const DisplayChildMenu = ({ item }) => {
  const { pathname } = useLocation();
  const [isActive, setIsActive] = useState(false);
  return item?.hasAccess === undefined || item?.hasAccess === true ? (
    <React.Fragment key={`parent-menu`}>
      <a
        className="nav-link"
        href={item?.link}
        onClick={() => setIsActive(!isActive)}
      >
        {item.name} &nbsp;{' '}
        {item?.childMenu?.length ? (
          <span
            className={
              isActive ? 'icon icon-chevron-up' : 'icon icon-chevron-down'
            }
          ></span>
        ) : (
          ''
        )}{' '}
      </a>

      {isActive && item?.childMenu && item?.childMenu?.length ? (
        <>
          <ul className="navbar-nav">
            {item?.childMenu &&
              item?.childMenu?.map((innerItem, Menuindex) => {
                return innerItem?.hasAccess === undefined ||
                  innerItem?.hasAccess === true ? (
                  <li className="nav-item" key={`child-menu${Menuindex}`}>
                    {innerItem?.link ? (
                      <Link to={innerItem?.link}>{innerItem?.name}</Link>
                    ) : (
                      <>
                        <DisplayChildMenu item={innerItem} />
                      </>
                    )}
                  </li>
                ) : (
                  ''
                );
              })}
          </ul>
        </>
      ) : (
        ''
      )}
    </React.Fragment>
  ) : (
    ''
  );
};

const Header = () => {
  const [childMenu, setChildMenu] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [activeUserRoles, setActiveUserRoles] = useState([]);
  const userDetailsData = useSelector((state) => state.auth);
  const { pathname } = useLocation();

  const { oktaAuth, authState } = useOktaAuth();

  const {
    user,
    activeRequest,
    userType,
    actualUserType,
    mainUserType,
    userActiveRolePermissions,
    userNotifyInfo
  } = userDetailsData;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const companyRegisterData = useSelector(
    (state) => state?.companyRegisterData
  );
  const { profile, getProfileStatus } = companyRegisterData;

  useEffect(() => {
    if (mainUserType === 'EXTERNAL') {
      if (getProfileStatus === 'SUCCESS') {
        if (profile?.profileStatus?.toLowerCase() === 'inactive') {
          setShowMenu(false);
        } else {
          setShowMenu(true);
        }
      }
    } else {
      setShowMenu(true);
    }
  }, [mainUserType, profile, getProfileStatus]);

  const logout = async () => {
    await oktaAuth.signOut();
  };

  useEffect(() => {
    const menusList = [
      { name: 'Home', link: '/' },
      {
        name: 'My Profile',
        link: '/my-profile',
        hasAccess: mainUserType === 'EXTERNAL' && showMenu
      },
      {
        name: 'Company Dashboard',
        hasAccess: hasAccess('company') && showMenu,
        childMenu: [
          {
            name: 'Onboard a new company',
            link: '/company-onboard',
            hasAccess: hasAccess('company', 'create')
          },
          {
            name: 'Search & Manage Companies',
            link: '/company-manage',
            hasAccess: hasAccess('company', 'read')
          }
        ]
      },
      {
        name: 'User Dashboard',
        hasAccess: hasAccess('users') && showMenu,
        childMenu: [
          {
            name: 'Search Users',
            link: '/search-users',
            hasAccess: hasAccess('users', 'read')
          },
          {
            name: 'Manage CCO Users',
            link: '/cco-users-manage',
            hasAccess: hasAccess('users', 'deactivate')
          }
        ]
      },
      {
        name: 'Requests',
        hasAccess: hasAccess('requests') && showMenu,
        notifyCount: userNotifyInfo?.requestsCount,
        childMenu: [
          {
            name: 'Active Requests',
            link: '/active-request',
            hasAccess: hasAccess('requests', 'read'),
            notifyCount: userNotifyInfo?.requestsCount
          },
          {
            name: 'Request History',
            link: '/request-history',
            hasAccess: hasAccess('requests', 'readHistory')
          }
        ]
      },
      {
        name: 'Invitations',
        link: '/invitations',
        hasAccess: hasAccess('invitations', 'read') && showMenu,
        notifyCount: userNotifyInfo?.invitationsCount
      },

      {
        name: 'Profile Review',
        hasAccess:
          (hasAccess('companyAccountRevalidation') ||
            hasAccess('userAccountReview')) &&
          showMenu,
        childMenu: [
          {
            name: 'Company Account Review',
            link: '/company-account-revalidation',
            hasAccess: hasAccess('companyAccountRevalidation', 'read')
          },
          {
            name: 'User Account Review',
            link: '/user-account-review',
            hasAccess: hasAccess('userAccountReview', 'read')
          }
        ]
      },
      {
        name: 'Admin',
        childMenu: [
          {
            name: 'Company Off-boarding Review',
            link: '/company-account-approval',
            hasAccess: hasAccess('companyAccountApproval')
          },
          {
            name: 'Manage Company Type',
            link: '/company-type-manage',
            hasAccess: hasAccess('companyType')
          },

          {
            name: 'Audit Reports',
            hasAccess: hasAccess('auditReports'),
            childMenu: [
              {
                name: 'Company Account Modification Report',
                link: '/company-account-modification-audit-reports',
                hasAccess: hasAccess('auditReports')
              },
              {
                name: 'Company Account Lifecycle Report',
                link: '/company-account-lifecycle-audit-reports',
                hasAccess: hasAccess('auditReports')
              },
              {
                name: 'User Account Lifecycle Report',
                link: '/user-account-lifecycle-audit-reports',
                hasAccess: hasAccess('auditReports')
              },
              {
                name: 'Scheduled Report',
                link: '/audit-report-schedule',
                hasAccess: hasAccess('auditReportSchedule')
              }
              // {
              //   name: 'Company Account Lifecycle Report',
              //   link: '',
              //   hasAccess: hasAccess('auditReports')
              // }
            ]
          }
        ],
        hasAccess:
          (hasAccess('companyType') || hasAccess('auditReports')) && showMenu
      }
    ];
    setChildMenu(menusList);

    setActiveUserRoles(
      userActiveRolePermissions?.filter((item) => {
        return item?.maintainedAt?.toLowerCase() === 'oneaccess';
      })
    );
  }, [userActiveRolePermissions, showMenu, userNotifyInfo]);

  useEffect(() => {
    console.log(
      'User Roles : ',
      userActiveRolePermissions?.map((item) => item?.displayName)
    );
  }, [userActiveRolePermissions]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
    function handleClick(e) {
      if (menuRef && menuRef.current) {
        const ref = menuRef.current;
        if (!ref.contains(e.target)) {
          setIsMenuOpen(false);
        }
      }
    }
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [pathname]);

  const menuClose = (event) => {
    event.preventDefault();
    setIsMenuOpen(false);
  };

  const menuOpen = (event) => {
    event.preventDefault();
    setIsMenuOpen(true);
  };

  return (
    <>
      <header className="header" id="scregheader">
        <div className="" style={{ width: '100%' }}>
          <div className="container">
            <div className="header-panels">
              <a rel="noreferrer" onClick={menuOpen} className="menu-open-btn">
                <span className="icon-list-menu"></span>
              </a>
              <div className="header-panel">
                <a
                  className="header__logo"
                  href="http://www.cisco.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  <span className="icon-cisco"></span>{' '}
                </a>
                <h1 className="header__title">SC User Registration</h1>
              </div>

              <div className="header-panel header-panel--right">
                {activeRequest && (
                  <>
                    <Link to="/" className="header-item">
                      <span className="icon-home"></span>
                    </Link>
                    <span className="space-bar text-gray-400">|</span>
                  </>
                )}
                <div>
                  {/* {(userType?.includes('pa') ||
                    actualUserType?.includes('pa_inprocess')) && (
                    <>
                      <div className="qtr-margin-right">
                        <span className="label label--light label--bordered label--small">
                          Partner Administrator{' '}
                        </span>
                      </div>
                    </>
                  )}

                  {userType?.includes('pam') ? (
                    <div className="qtr-margin-right">
                      <span className="label label--light label--bordered label--small">
                        Partner Account Manager
                      </span>
                    </div>
                  ) : (
                    ''
                  )} */}

                  {userActiveRolePermissions?.length ? (
                    <LimitDropdown
                      data={[userActiveRolePermissions[0]?.displayName]}
                    />
                  ) : null}

                  {/* {userType &&
                  userType.filter((item) => item?.toLowerCase() !== 'requester')
                    .length ? (
                    <LimitDropdown
                      data={userType
                        .filter((item) => item?.toLowerCase() !== 'requester')
                        .map((item) => {
                          if (item?.toLowerCase() === 'pam') {
                            return 'Partner Account Manager';
                          } else if (
                            item?.toLowerCase() === 'pa' ||
                            item?.toLowerCase() === 'pa_inprocess'
                          ) {
                            return 'Partner Administrator';
                          }

                          return item?.toUpperCase();
                        })}
                    />
                  ) : null} */}
                </div>

                {authState?.isAuthenticated && user && user.name && (
                  <div>
                    <Dropdown
                      title={
                        <span className="text-capitalize">{user.name}</span>
                      }
                      content={
                        <div className="user-info-wrap">
                          <div className="user-info-item qtr-padding  border-bottom">
                            <div className="text-capitalize">
                              <b>{user.name}</b>
                            </div>
                            <div>{user.email}</div>
                          </div>
                          {/* <div className="user-info-item qtr-padding">
                            <span>
                              <b>One Access Roles</b>
                            </span>
                            <div>
                              {activeUserRoles?.length === 0 ? (
                                <div className="panel panel--bordered half-margin-top">
                                  No Roles{' '}
                                </div>
                              ) : null}
                              {activeUserRoles &&
                                activeUserRoles.map((item, index) => {
                                  return (
                                    <div
                                      key={`role-item${index}`}
                                      className="role-item qtr-margin-bottom"
                                    >
                                      <span className="label label--light label--bordered label--small">
                                        {item?.displayName || item?.name}
                                      </span>
                                    </div>
                                  );
                                })}
                            </div>
                          </div> */}
                        </div>
                      }
                      dropDownStyle={{
                        width: '380px',
                        right: 0,
                        left: 'inherit'
                        // minHeight: '200px'
                      }}
                    />
                  </div>
                )}
                <span className="space-bar text-gray-400">|</span>
                <a
                  href="https://cisco.sharepoint.com/sites/SCTDataSecurityandPrivacy/SitePages/Identity-and-Access-Governance.aspx"
                  target="_blank"
                  rel="noreferrer"
                  className="header-item header-icon-item"
                  title="Help"
                >
                  <span className="icon-help-alt"></span>
                </a>
                <span className="space-bar text-gray-400">|</span>
                {authState?.isAuthenticated && (
                  <div className="header-item">
                    <button
                      type="submit"
                      className="btn btn--ghost btn--circle btn--small"
                      title="Logout"
                      onClick={logout}
                    >
                      <span className="icon-sign-out"></span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <nav className="navbar">
            <div
              className="secondary-header container"
              style={{ width: '100%' }}
            >
              <div className="header-menu-container">
                <div className="header-menu">
                  {childMenu.map((item, index) => {
                    return item?.hasAccess === undefined ||
                      item?.hasAccess === true ? (
                      <DropdownMenu
                        key={`menu-${index}`}
                        name={
                          <>
                            {item.name}{' '}
                            {item?.notifyCount ? (
                              <span className="badge qtr-margin-left">
                                {item?.notifyCount}
                              </span>
                            ) : (
                              ''
                            )}
                          </>
                        }
                        link={item?.link}
                        childMenu={item?.childMenu?.map(
                          (childItem, childIndex) => {
                            const tempChildItem = { ...childItem };
                            tempChildItem.name = !childItem?.notifyCount ? (
                              childItem.name
                            ) : (
                              <>
                                {childItem.name}
                                {childItem?.notifyCount ? (
                                  <span className="badge qtr-margin-left">
                                    {childItem?.notifyCount}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </>
                            );

                            return tempChildItem;
                          }
                        )}
                      />
                    ) : (
                      ''
                    );
                  })}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <nav className={`sm-screen-navbar ${isMenuOpen ? 'show' : ''}`}>
        <div className="container-fluid">
          <div className="">
            <a rel="noreferrer" onClick={menuClose} className="menu__close">
              <span className="icon-close"></span>
            </a>
          </div>
          <div className="base-margin-top">
            <ul>
              {childMenu.map((item, index) => {
                return item?.hasAccess === undefined ||
                  item?.hasAccess === true ? (
                  <li className="nav-item active" key={`parent-menu${index}`}>
                    <DisplayChildMenu item={item} />
                  </li>
                ) : (
                  ''
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Header;
