import { CompanyTypeDef } from '../components/pages/CompanyOnboard/companyOnboardEditTypes';
import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export const fetchCecUsers = async (search?: string) => {
  try {
    const res = await httpUtil.post(`/api/am/v1/pamDetails`, {
      search: search,
      firstName: '',
      lastName: ''
    });
    return res || [];
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching users ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const fetchInternalUsers = async (search?: string) => {
  try {
    const res = await httpUtil.post(`/api/am/v1/audit/searchCECUsers`, {
      search: search
    });
    return res || [];
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching users ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const companyRoleUserUpdate = async (
  userRole: string,
  userType: string[],
  addList: any[],
  removeList: any[],
  companyData: any,
  editSource: string
) => {
  const userRoleText = userRole?.toUpperCase();
  try {
    const res = await httpUtil.post(`/api/am/dashboard/v1/updatePamData`, {
      ...companyData,
      requestType: userRole,
      userType: userType,
      addList: addList,
      removeList: removeList,
      editSource: editSource
    });

    notify(
      `${
        userRoleText === 'PAM'
          ? userRoleText
          : userRole?.charAt(0)?.toUpperCase() + userRole?.slice(1)
      } is updated successfully.`,
      'success'
    );
    return res || [];
  } catch (error: any) {
    const errorMessage = `An error occurred while updating ${
      userRoleText === 'PAM' ? userRoleText : userRole
    } ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const companySponsorUpdate = async (
  userRole: string,
  userType: string[],
  addList: any[],
  removeList: any[],
  companyData: any,
  editSource: string
) => {
  try {
    const res = await httpUtil.post(`/api/am/dashboard/v1/updateSponsorData`, {
      ...companyData,
      requestType: userRole,
      userType: userType,
      addList: addList,
      removeList: removeList,
      editSource: editSource
    });
    notify(`Sponsor is updated successfully.`, 'success');
    return res || [];
  } catch (error: any) {
    const errorMessage = `An error occurred while updating sponsor ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const companyOperationalAdminUpdate = async (
  userType: string[],
  updateData: any
) => {
  try {
    const res = await httpUtil.post(`/api/am/dashboard/v1/updateOpData`, {
      ...updateData,
      userType: userType
    });
    notify(`Operational admin is updated successfully.`, 'success');
    return res || [];
  } catch (error: any) {
    const errorMessage = `An error occurred while updating Operational admin ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const fetchSCIAMUsers = async (
  page: number = 1,
  search?: string,
  domains: string[] = [],
  companyId?: string
) => {
  try {
    const res = await httpUtil.post(
      `/api/am/dashboard/v1/company/PA/search`,

      {
        companyId,
        search,
        emailDomains: domains
      }
    );
    return res || [];
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching users ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const checkNewPAExist = async (email: string) => {
  try {
    const res = await httpUtil.post(
      `/api/am/dashboard/v1/findUsersUsingEmail`,

      {
        searchKey: email
      }
    );
    return !!res;
  } catch (error: any) {
    const errorMessage = `An error occurred while checking PA user ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const fetchOnboadingCompanies = async (filter: any = {}) => {
  try {
    const res = await httpUtil.post(
      `/api/am/dashboard/v1/company/searchCompanyAndNodeDetails`,
      filter
    );
    let tempRes = [];
    try {
      tempRes = Array.isArray(res?.data) ? res?.data : [];
    } catch (err) {
      throw new Error(
        err instanceof Error ? err?.message : 'Something went wrong'
      );
    }
    return tempRes || [];
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching companies ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const fetchExistingCompanySearch = async (companyId: string) => {
  try {
    const res = await httpUtil.post<unknown, CompanyTypeDef>(
      `/api/am/dashboard/v1/company/existingCompanySearch`,
      {
        companyId
      }
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateCompanyType = async (payload: any) => {
  try {
    const res = await httpUtil.post(
      `/api/am/dashboard/v1/company/update/companyType`,
      payload
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const sendInvitation = async (payload: any) => {
  try {
    const res = await httpUtil.post(
      `/api/am/dashboard/v1/userRequestData`,
      payload
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateCountries = async (payload: any) => {
  try {
    const res = await httpUtil.post(
      `/api/am/dashboard/v1/company/update/countries`,
      payload
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateCustomers = async (payload: any) => {
  try {
    const res = await httpUtil.post(
      `/api/am/dashboard/v1/company/update/customers`,
      payload
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateOrgs = async (payload: any) => {
  try {
    const res = await httpUtil.post(`/api/am/dashboard/v1/updateOrg`, payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchCompanyOffBoardingDetails = async (payload: any = {}) => {
  try {
    const res = await httpUtil.post<unknown, any>(
      `/api/am/dashboard/v1/company/offboardDetails`,
      payload
    );
    return res;
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching company off board Details ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const paInviteUpdate = async (body: any = {}) => {
  try {
    const res = await httpUtil.post(
      `/api/am/dashboard/v1/resendCancelRemove/invitepaRequest`,
      body
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchCompaniesFilter = async (search?: string) => {
  try {
    const res = await httpUtil.post(
      `/api/am/dashboard/v1/get/companyName/search`,
      {
        companyName: search
      }
    );
    return res || [];
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching users ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};
