import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllOrganization
  // fetchCompanyDomains
} from '../../../../actions';
import Select, { SelectOption } from '../../../molecules/Select';
import InlineEdit from '../../../molecules/InlineEdit/InlineEdit';
import httpUtil from '../../../../helpers/interceptor';
import notify from '../../../../helpers/notification';
import Loader from '../../../atoms/Loader';
import Dialog from '../../../atoms/Dialog';
import {
  arraysContainSameElements,
  getAddRemovedPayload
} from '../../../../helpers/common';
import { updateOrgs } from '../../../../services';

export interface OrgEditProps {
  open?: boolean;
  onOpenChange?: Function;
  companyType?: string;
  value?: any;
  userType?: string[];
  isLoading?: boolean;
  companyId?: string;
  companyNodeId?: string;
  updateOrgFormValue?: Function;
  title?: React.ReactNode;
  isOrgMandatory?: boolean;
  editSource: string;
}

function OrgEdit({
  open,
  onOpenChange,
  companyType,
  value,
  companyId,
  companyNodeId,
  isLoading,
  userType,
  updateOrgFormValue,
  title,
  isOrgMandatory,
  editSource
}: OrgEditProps) {
  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [status, setStatus] = useState<string | undefined>();
  const [error, setError] = useState<any | undefined>();
  const [edit, setEdit] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<any>();
  const [validation, setValidation] = useState<any>({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

  const getOrganizationStatus = useSelector(
    (state: any) => state.onboardCompany?.getOrganizationStatus
  );

  const companyOnboardData = useSelector((state: any) => state.onboardCompany);
  const { organizations: organizationsOptions = [] } = companyOnboardData;

  useEffect(() => {
    setEdit(!!open);
  }, [open]);

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  useEffect(() => {
    if (companyType && edit) {
      dispatch(fetchAllOrganization());
    }
  }, [companyType, edit]);

  const onEditChange = () => {
    setEdit(true);
    if (onOpenChange) {
      onOpenChange(true);
    }
  };

  const onEditCancel = () => {
    setEditValue(value);
    setEdit(false);
    if (onOpenChange) {
      onOpenChange(false);
    }
    setIsSubmitted(false);
  };

  useEffect(() => {
    checkValidation();
  }, [editValue]);

  const checkValidation = () => {
    let errors: any = {};

    if (isOrgMandatory) {
      if (!editValue || !editValue?.length) {
        errors.organizations = 'Organization is required';
      }
    }

    setValidation(errors);
    return Object.keys(errors).length === 0;
  };

  const organizationChange = (newValue = []) => {
    const selectedOrg = newValue?.map((item: any) => item.value) || [];

    setEditValue(selectedOrg);
  };

  const isOrgRemoved = (prevOrg: string[], newOrg: string[]): boolean => {
    return (
      prevOrg.filter(
        (item) =>
          !newOrg.some(
            (newItem) => newItem.toLowerCase() === item.toLowerCase()
          )
      ).length > 0
    );
  };

  const onClickSave = async (confirm: boolean = false) => {
    if (isOrgRemoved(value || [], editValue || []) && !confirm) {
      setConfirmDialogOpen(true);
    } else {
      setIsSubmitted(true);
      setConfirmDialogOpen(false);

      if (!checkValidation()) {
        return;
      }

      const addRemovedPayload = getAddRemovedPayload(
        value || [],
        editValue || []
      );

      const payload = {
        companyId,
        companyNodeId,
        userType,
        requestType: 'ORG',
        organizations: editValue || [],
        addOrgsList: addRemovedPayload.addedList,
        removeOrgsList: addRemovedPayload.removedList,
        editSource: editSource
      };

      setStatus('PENDING');
      try {
        await updateOrgs(payload);
        setStatus('SUCCESS');
        setEdit(false);

        if (onOpenChange) {
          onOpenChange(false);
        }

        if (updateOrgFormValue) {
          updateOrgFormValue({ organizations: editValue });
        }

        notify('Organization Details is updated successfully.', 'success');
      } catch (e) {
        setStatus('ERROR');
        const errorMessage = `An error occurred while updating Organization ${
          error?.response?.data?.error
            ? ': ' + error?.response?.data?.error
            : ''
        }`;
        notify(errorMessage, 'error');
      }
    }
    // httpUtil
    //   .post(`/api/am/dashboard/v1/updateOrg`, payload)
    //   .then((_) => {
    //     setStatus('SUCCESS');
    //     setEdit(false);

    //     if (onOpenChange) {
    //       onOpenChange(false);
    //     }

    //     if (updateOrgFormValue)
    //       updateOrgFormValue({
    //         organizations: editValue
    //       });

    //     notify('Organization Details is updated successfully.', 'success');
    //   })
    //   .catch((err) => {
    //     setStatus('ERROR');
    //     notify(err.message, 'error');
    //   });
  };

  const confirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <>
      {isSubmitted && edit && validation?.organizations ? (
        <div className="alert alert--warning">
          <div className="alert__icon icon-warning-outline"></div>
          <div className="alert__message">{validation?.organizations}</div>
        </div>
      ) : null}

      <InlineEdit
        isDisableSave={arraysContainSameElements(value, editValue)}
        title={
          <label
            htmlFor="select-additionalOrganization"
            className={`text-weight-700 ${isOrgMandatory ? 'required' : ''}`}
          >
            Company Orgs
          </label>
        }
        readContent={
          <div className="row half-padding-top half-padding-bottom">
            <div className="col-md-12 ">
              <div className="form-group">
                <div className="form-group__text">
                  <div className="edit-form-value-holder">
                    {editValue &&
                      editValue?.map((item: any) => item).join(', ')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        isEditing={edit}
        onChangeEdit={onEditChange}
        onCancelEdit={onEditCancel}
        isLoading={
          status === 'PENDING' ||
          isLoading ||
          getOrganizationStatus === 'PENDING'
        }
        editContent={
          <div className="edit-form-value-holder">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="form-group__text">
                    <Select
                      value={
                        editValue?.map((item: any) => {
                          return {
                            value: item,
                            label: item
                          };
                        }) || []
                      }
                      isMulti
                      name="organizations"
                      options={
                        organizationsOptions?.length
                          ? organizationsOptions.map((item: any) => {
                              return {
                                value: item?.organizationCode,
                                label: `${item?.organizationName} (${item?.organizationCode})`
                              };
                            })
                          : []
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={organizationChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        onSave={onClickSave}
      />

      <Dialog
        open={confirmDialogOpen}
        size="small"
        onDialogClose={confirmDialogClose}
        closeOnClickOutside={false}
        title={`Confirm`}
        body={
          <div>
            <div className="alert alert--warning">
              <div className="alert__message">
                This company org might be assigned as the primary and secondary
                org in the user profile. Org unassignment will result in removal
                of org access from user’s profile.
              </div>
            </div>

            <h4>Do you still want to perform this action?</h4>
            {status === 'PENDING' && (
              <div className="center-holder-wrap">
                <Loader />
              </div>
            )}
          </div>
        }
        footer={
          <div>
            <button
              type="button"
              onClick={confirmDialogClose}
              className="btn btn--ghost"
              disabled={status === 'PENDING'}
            >
              No
            </button>
            <button
              type="button"
              onClick={() => onClickSave(true)}
              className="btn btn--primary"
              disabled={status === 'PENDING'}
            >
              Yes
            </button>
          </div>
        }
      />
    </>
  );
}

export default OrgEdit;
