import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../atoms/Loader';
import { fetchChildCompany } from '../../../services/UserRequestService';

export interface ChildCompanyProps {
  companyName: string;
  companyTypeId: string;
  name: string;
  value: string;
  onChange: Function;
  required?: boolean;
  disabled?: boolean;
}

const ChildCompany = ({
  companyName,
  companyTypeId,
  name,
  value,
  onChange,
  required,
  disabled
}: ChildCompanyProps) => {
  const authData = useSelector((state: any) => state?.auth);
  const { actualUserType } = authData;
  const [status, setStatus] = useState<string | undefined>();
  const [childCompanyList, setChildCompanyList] = useState([]);

  useEffect(() => {
    fetchChildCompanies(companyName, companyTypeId);
  }, [companyName]);

  const fetchChildCompanies = (
    companyName: string,
    companyTypeIdRef: string
  ) => {
    setStatus('PENDING');
    return fetchChildCompany(
      companyName,
      companyTypeIdRef,
      actualUserType
        ? actualUserType?.map((item: string) => item?.toUpperCase())
        : []
    )
      .then((res: any) => {
        setChildCompanyList(res || []);
        setStatus('SUCCESS');
        return res;
      })
      .catch((error) => setStatus('FAILURE'));
  };

  const onChildCompanyChange = (
    parentCompanyId: string,
    childCompanyId: string,
    supplierSiteId: string
  ) => {
    if (onChange) {
      onChange(parentCompanyId, childCompanyId, supplierSiteId);
    }
  };

  return (
    <>
      {status === 'PENDING' && <Loader />}
      {status === 'SUCCESS' &&
        childCompanyList?.map(
          (
            {
              companyId: parentCompanyId,
              companyNodeId,
              vendorName,
              siteId,
              address1,
              address2,
              city,
              state,
              country,
              postalCode
            },
            index
          ) => (
            <div
              className="panel panel--bordered qtr-margin-bottom"
              key={`ch${index}`}
            >
              <div className="form-group" style={{ lineHeight: '10px' }}>
                <label className="radio" style={{ width: '100%' }}>
                  <input
                    type="radio"
                    name={name}
                    value={companyNodeId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChildCompanyChange(
                        parentCompanyId,
                        companyNodeId,
                        siteId
                      )
                    }
                    checked={companyNodeId == value}
                    required={required}
                    data-errormessage-value-missing="Please input something"
                    disabled={disabled}
                  />
                  <span className="radio__input"></span>
                  <div className="radio__label" style={{ width: '100%' }}>
                    {/* <div className="qtr-margin-bottom text-weight-500">
                      {vendorName || '-'}
                    </div> */}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="">
                          <div className="text-size-10">
                            {/* <div className="qtr-margin-bottom">
                              <span className="text-weight-700">Site Id :</span>{' '}
                              {siteId}
                            </div> */}
                            <span className="">
                              {/* <span className="text-weight-700">
                                Address :{' '}
                              </span>{' '} */}
                              {address1 || '-'}{' '}
                              {address2 ? ', ' + address2 : null}
                              {city ? ', ' + city : null}
                              {state ? ', ' + state : null}
                              {country ? ', ' + country : null}
                              {postalCode ? ', ' + postalCode : null}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          )
        )}
      {status === 'SUCCESS' && childCompanyList?.length == 0 && (
        <div className="alert alert--info">
          <div className="alert__message">No child company available</div>
        </div>
      )}
      {status === 'FAILURE' && (
        <div className="alert alert--warning">
          <div className="alert__message">
            An error occurred while fetching child company
          </div>
        </div>
      )}
    </>
  );
};

export default ChildCompany;
